/* eslint-disable */
import classNames from 'classnames';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Split } from '@geoffcox/react-splitter';
import DragIcon from '../assets/drag-icon.svg';
import ArrowLeftIcon from 'remixicon-react/ArrowLeftLineIcon';
import GlobalIcon from 'remixicon-react/GlobalLineIcon';
import { useSearchParams } from 'react-router-dom';
import RocketIcon from 'remixicon-react/Rocket2LineIcon';
import LazyAvatar from '../assets/sloth-avatar-blue.svg';
import InfoIcon from 'remixicon-react/InformationLineIcon';

import {
  subscribeToAppInstanceUpdates,
  SubscribeToAppInstanceUpdatesParams,
} from '../api/AppStatesSocket';
import {
  getApp,
  getVerifiedCustomDomainForApp,
  checkVerifiedCustomDomainAccessibility,
  checkAppLinkAccessibility,
} from '../api/BuilderApi';

import {
  createOrGetInstanceForApp,
  getAppInstance,
  getCurrentAppVersion,
  resetSocketConnectionStateForInstance,
  startInstance,
  stopInstance,
  subscribeToAppInstanceTty,
  SubscribeToAppInstanceTtyParams,
  TTYCapabilities,
} from '../api/ClientApi';
import {
  catchUpWithPreviousMessages,
  catchUpWithPreviousMessagesSavedOnLogs,
  convertFromTTYToMessage,
  sendUserMessageToApp,
  SOURCE_EXCEPTION,
  StatusMessageType,
  getTimestampForOldestMessage,
} from '../api/ClientApiHelper';
import {
  ApiError,
  AppInstanceState,
  LazyAppWithMinimalAppVersionWithSessionStateId,
  LazyAppInstanceMinimal,
  LazyAppVersion,
  PingClientParams,
} from '../api/generated';
import { FrontendEvents } from '../api/StatsApi';
import AppInstanceStatusDisplay from '../components/AppInstanceStatusDisplay';
import CustomDomainsModal from '../components/CustomDomainsModal';
import Chat from '../components/chat/Chat';
import MessageListRenderer from '../components/chat/MessageListRenderer';
import Button from '../components/base/Button';
import { RunAppButton } from '../components/base/RunAppButton';
import { PublishCostWarning } from '../components/PublishedAppCostWarning';
import { PublishedAppRunningCostNotification } from '../components/PublishedAppRunningCostNotification';
import { BrowserFrame } from '../components/base/BrowserFrame';
import { AppAutoShutDownTimer } from '../components/AppAutoShutDownTimer';
import ImageLoader, { fetchAppImage, getImageName, setAppFavIcon } from '../components/ImageLoader';
import { UpgradeToProNotice } from '../components/UpgradeToProNotice';
import { UpdateAppHeader } from '../components/UpdateAppHeader';
import { useAppStore } from '../store/app';
import { useAuthStore } from '../store/auth';
import { useChatStore } from '../store/chat';
import { Message, ActionToRunAVersionOfTheApp, ActionForParentWindow } from '../types';
import { isMobileDevice, updateCachedValues } from '../utils/deviceDimensions';
import useRerenderOnResize from '../utils/useRerenderOnResize';
import LoadingPage from './LoadingPage';
import { TTYMessage } from '../api/TtyHelper';
import { v4 as uuidv4 } from 'uuid';
import { TOP_BAR_APP_RUNNING_STATE } from '../constants';
import { useEventEmitter } from '../components/eventEmitterHook';

export interface AppRunContextInfo {
  isNewVersionAvailable: boolean;
  isRunning: boolean;
  appId?: string;
  instanceId?: string;
  isAppRunMode: boolean;
}

export const AppRunContext = createContext<AppRunContextInfo>({
  isNewVersionAvailable: false,
  isRunning: false,
  isAppRunMode: false,
});

interface AppRunProps {
  testing?: boolean;
}

const MAX_RETRIES_TO_CHECK_FOR_APP_LINK_TO_BE_ACCESSIBLE = 5;

// eslint-disable-next-line max-lines-per-function, max-statements
const AppRun = ({ testing = false }: AppRunProps) => {
  useRerenderOnResize();

  const params = useParams();
  const { emitEvent: _emitEvent } = useEventEmitter();
  const urlInstanceId = params.instanceId as unknown as string;
  const urlAppId = params.appId as unknown as string;

  const [searchParams, _] = useSearchParams();

  // ------------
  // Local States
  // ------------
  const [app, setApp] = useState<LazyAppWithMinimalAppVersionWithSessionStateId | null>(null);
  const [verifiedCustomDomain, setVerifiedCustomDomain] = useState<string | null>(null);
  const [isAllPreviousMessagesLoaded, setIsAllPreviousMessagesLoaded] = useState(false);
  const [appVersion, setAppVersion] = useState<LazyAppVersion | null>(null);
  const [initialLoadingError, setInitialLoadingError] = useState('');
  const [isAppStatusKnown, setIsAppStatusKnown] = useState(false);
  const [isAppRestarting, setAppRestarting] = useState(false);
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
  const [isAppStopping, setAppStopping] = useState(false);
  const [openAppUrlFromMessages, setOpenAppUrlFromMessages] = useState<string | null>(null);
  const [sqliteWebUrlFromMessages, setSqliteWebUrlFromMessages] = useState<string | null>(null);
  const [isCustomDomainModalOpen, setIsCustomDomainModalOpen] = useState<boolean>(
    !!searchParams.get('configure_custom_domains')
  );
  const [openAppUrl, setOpenAppUrl] = useState<string | null>(null);
  const [appState, setAppState] = useState<AppInstanceState>(AppInstanceState.NEVER_RUN);
  const [backendAppState, setBackendAppState] = useState<AppInstanceState>(
    AppInstanceState.NEVER_RUN
  );
  const [isTestingApp, setIsTestingApp] = useState<boolean>(false);

  // -------------
  // Zustand Store
  // -------------
  const { setAppName, setAppImageUrl } = useAppStore();
  const { userPermissions } = useAuthStore();

  const {
    instance,
    messages,
    handleAppStartingMessage,
    handleAppInstallingDependenciesMessage,
    handleAppStoppingMessage,
    handleAppNotRunningMessage,
    handleAppRunningMessage,
    setInstance,
    setUserInputLoading,
    setUserInputBlocked,
    setStatusMessage,
    disableRevertFunction,
    setHideLoadingStop,
    resetError,
    setError,
    appendMessages,
    prependMessages,
    setMessages,
    setAppsMessageLoadingFailedError,
  } = useChatStore();

  // ----
  // Refs
  // ----
  const ttyWebsocket = useRef<WebSocket | null>(null);
  const appStateWebsocket = useRef<WebSocket | null>(null);
  const lastSubscribedTTYInstance = useRef<string | null>(null);
  const builderSessionStateIdOfInstance = useRef<string | null>(null);
  const appStatusRef = useRef<HTMLDivElement>(null);
  const idsOfPreviouslyProcessedMessages = useRef<Set<string>>(new Set());
  const ttyCapabilities = useRef<TTYCapabilities>({
    supports_app_hot_reload: false,
    supports_min_timestamp_param: false,
  });
  const lastMessageTimestamp = useRef<Date>(new Date(1970));
  const lastMessageFromHistoryFile = useRef<string | null>(null);
  const appInstanceState = useRef<AppInstanceState>(AppInstanceState.NEVER_RUN);
  const shouldAttemptToConnect = useRef<boolean>(false);
  const displayTimerConfigured = useRef<boolean>(false);
  const appRestartInProgress = useRef<boolean>(false);
  const isNewVersionAvailableRef = useRef<boolean>(false);
  const hasFetchedFromLogsAlready = useRef<boolean>(false);
  const messagesQueuedForDisplaying = useRef<Message[]>([]);
  const previousMessagesQueuedForDisplaying = useRef<Message[]>([]);
  const lastAppStateTimestamp = useRef<Date | null>(null);
  const timestampOfOldestLoadedMessage = useRef<Date>();

  // eslint-disable-next-line max-lines-per-function
  const updateAppState = (newAppState: AppInstanceState, timestamp: Date | null) => {
    if (timestamp && lastAppStateTimestamp.current && timestamp <= lastAppStateTimestamp.current) {
      return;
    }
    lastAppStateTimestamp.current = timestamp;
    appInstanceState.current = newAppState;
    setAppState(newAppState);

    const STARTING_EVENTS = [
      AppInstanceState.STARTING,
      AppInstanceState.STARTED,
      AppInstanceState.INSTALLING_DEPENDENCIES,
      AppInstanceState.RUNNING,
    ];
    shouldAttemptToConnect.current = STARTING_EVENTS.includes(appInstanceState.current);
    const ENDING_EVENTS = [
      AppInstanceState.ENDED,
      AppInstanceState.ENDED_WITH_ERROR,
      AppInstanceState.STOPPED,
      AppInstanceState.STOPPED_AUTOMATICALLY,
    ];
    if (STARTING_EVENTS.concat(ENDING_EVENTS).includes(appInstanceState.current)) {
      if (ENDING_EVENTS.includes(appInstanceState.current)) {
        if (instance) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          catchUpWithPreviousMessagesSavedOnLogs(
            instance.id as unknown as string,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            messageHookForMessagesFromHistory,
            idsOfPreviouslyProcessedMessages,
            lastMessageFromHistoryFile
          );
        }
      }
    }

    if (newAppState === AppInstanceState.STARTING) {
      handleAppStartingMessage();
    } else if (newAppState === AppInstanceState.STOPPING) {
      handleAppStoppingMessage();
    } else if (newAppState === AppInstanceState.INSTALLING_DEPENDENCIES) {
      handleAppInstallingDependenciesMessage();
    } else if (newAppState === AppInstanceState.RUNNING) {
      handleAppRunningMessage();
    } else if (newAppState !== AppInstanceState.STARTED) {
      handleAppNotRunningMessage();
    }
  };

  const loadVerifiedCustomDomain = (appId?: string) => {
    if (appId && process.env.REACT_APP_FLAG_ENABLE_CUSTOM_DOMAIN === 'true' && !testing) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return getVerifiedCustomDomainForApp(appId).then((customDomain) => {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
        setVerifiedCustomDomain(customDomain ? `https://${customDomain}` : '');
        return null;
      });
    }
  };

  const updateBackendAppState = (newAppState: AppInstanceState, timestamp: Date | null) => {
    setBackendAppState(newAppState);
    updateAppState(newAppState, timestamp);
  };

  const updateAppVersion = (instanceParam?: LazyAppInstanceMinimal) => {
    const appId = instanceParam?.app_id || instance?.app_id;
    // Asynchronously update the current version.
    if (appId) {
      getCurrentAppVersion(appId, !!testing)
        .then((version) => {
          return version && setAppVersion(version);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.debug('Error retrieving current version', err);
        });
    }
  };

  const loadSession = async () => {
    let thisInstance: LazyAppInstanceMinimal;
    if (urlAppId) {
      thisInstance = await createOrGetInstanceForApp(urlAppId, !!testing);
    } else if (urlInstanceId) {
      thisInstance = await getAppInstance(urlInstanceId);
    } else {
      return;
    }

    setInstance(thisInstance);
    useAppStore.setState({
      isTestingInstance: thisInstance.extra_app_instance_params?.is_testing_instance || false,
      autoUpdateTestApp: thisInstance.extra_app_instance_params?.auto_update_test_app,
      currentAppRam: thisInstance.extra_app_instance_params?.ram_mb,
    });
    updateAppVersion(thisInstance);
    return thisInstance;
  };

  const closeTtyWebsocket = () => {
    if (ttyWebsocket.current) {
      ttyWebsocket.current.close();
    }
  };

  const closeAppStateWebsocket = () => {
    if (appStateWebsocket.current) {
      appStateWebsocket.current.close();
    }
  };

  const setOldestMessageTimestamp = (messages: Message[]) => {
    const timestampOfOlderMessage = getTimestampForOldestMessage(messages);
    if (
      timestampOfOlderMessage &&
      (timestampOfOldestLoadedMessage.current
        ? timestampOfOlderMessage < timestampOfOldestLoadedMessage.current
        : true)
    ) {
      timestampOfOldestLoadedMessage.current = timestampOfOlderMessage;
    }
  };

  // eslint-disable-next-line max-lines-per-function
  const stopAppInstance = (): Promise<null> => {
    if (instance) {
      shouldAttemptToConnect.current = false;
      if (!ttyCapabilities.current.supports_app_hot_reload) {
        closeTtyWebsocket();
      }
      setOpenAppUrl(null);
      setOpenAppUrlFromMessages(null);
      updateAppState(AppInstanceState.STOPPING, null);
      setUserInputBlocked(true);
      setAppStopping(true);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return stopInstance(instance.id)
        .then(async () => {
          await loadSession();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          catchUpWithPreviousMessagesSavedOnLogs(
            instance.id as unknown as string,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            messageHookForMessagesFromHistory,
            idsOfPreviouslyProcessedMessages,
            lastMessageFromHistoryFile
          );
          return null;
        })
        .finally(() => {
          shouldAttemptToConnect.current = false;
          setUserInputLoading(false);
          setAppStopping(false);
          setSqliteWebUrlFromMessages(null);
          resetError();
        })
        .catch(() => {
          toast.error('There was an error stopping the app');
          // If the request failed, we don't know if anything actually happened to the
          // instance. Reset the state to the last one sent by the backend.
          updateAppState(backendAppState, null);
          return null;
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, compat/compat
    return Promise.resolve(null);
  };

  const emitEvent = (eventType: FrontendEvents) => {
    const appId = instance?.app_id;
    const instanceId = instance?.id;
    _emitEvent(eventType, appId, instanceId);
  };

  const messageHookToLoadPreviousMessages = (messages: Message[] | null) => {
    if (messages != null) {
      previousMessagesQueuedForDisplaying.current =
        previousMessagesQueuedForDisplaying.current.concat(messages);

      if (previousMessagesQueuedForDisplaying.current.length > 0) {
        prependMessages(previousMessagesQueuedForDisplaying.current);
        setOldestMessageTimestamp(previousMessagesQueuedForDisplaying.current);
        previousMessagesQueuedForDisplaying.current.length = 0;
      }
    }
  };

  const isStateOfStoppedApp = (state: AppInstanceState): boolean =>
    [
      AppInstanceState.ENDED,
      AppInstanceState.STOPPED,
      AppInstanceState.ENDED_WITH_ERROR,
      AppInstanceState.UNKNOWN_ERROR,
      AppInstanceState.NEVER_RUN,
    ].indexOf(state) >= 0;

  const isRunning =
    [
      AppInstanceState.STARTED,
      AppInstanceState.INSTALLING_DEPENDENCIES,
      AppInstanceState.RUNNING,
    ].indexOf(appState) >= 0;

  const consumeMessagesToShow = () => {
    if (messagesQueuedForDisplaying.current.length > 0) {
      appendMessages(messagesQueuedForDisplaying.current);
      setOldestMessageTimestamp(messagesQueuedForDisplaying.current);
      messagesQueuedForDisplaying.current.length = 0;
    }
    // Only check again after some time to avoid hanging the UI thread.
    setTimeout(consumeMessagesToShow, 1000 / 30);
  };

  const messageHook = (
    messages: Message[] | null,
    statusMessage: StatusMessageType,
    setAppStatusKnown: boolean
  ) => {
    if (messages != null) {
      messagesQueuedForDisplaying.current = messagesQueuedForDisplaying.current.concat(messages);

      if (!displayTimerConfigured.current) {
        displayTimerConfigured.current = true;
        consumeMessagesToShow();
      }
      if (setAppStatusKnown) {
        // Call setIsAppStatusKnown with a timeout to give scheduled
        // consumeMessagesToShow time to run and update the messages
        setTimeout(() => setIsAppStatusKnown(true), 1000);
      }
    }

    if (
      statusMessage !== null &&
      statusMessage !== undefined &&
      (statusMessage === AppInstanceState.RUNNING ||
        AppInstanceState.INSTALLING_DEPENDENCIES !== appInstanceState.current)
    ) {
      // ClientApiHelper only passes updated status messages to the hook if the app is
      // not waiting for input, so we can safely set userInputLoading=true here.
      setStatusMessage(statusMessage);
      // When we get an empty status message, it indicates that the code is in a place
      // where we shouldn't actually show the loading bar (the main module).
      setUserInputLoading(statusMessage !== '');
    }
  };

  function messageHookForMessagesFromHistory(
    messages: Message[] | null,
    statusMessage: StatusMessageType
  ) {
    if (messages) {
      messages = messages.filter(
        (entry) =>
          !entry.sentAt ||
          !timestampOfOldestLoadedMessage.current ||
          entry.sentAt >= timestampOfOldestLoadedMessage.current
      );
    }
    messageHook(messages, statusMessage, false);
  }

  const messageHookForMessagesFromTty = (
    messages: Message[] | null,
    statusMessage: StatusMessageType
  ) => {
    messageHook(messages, statusMessage, true);
  };

  // eslint-disable-next-line max-lines-per-function
  const startAppInstance = (
    builderSessionStateId = '',
    ramMb?: number,
    autoUpdateTestApp?: boolean
  ) => {
    if (instance) {
      setMessages([]); // Reset previous messages when starting application
      setIsAllPreviousMessagesLoaded(false);
      timestampOfOldestLoadedMessage.current = new Date();
      idsOfPreviouslyProcessedMessages.current.clear();
      setOpenAppUrl(null);
      setOpenAppUrlFromMessages(null);
      resetError();
      updateAppState(AppInstanceState.STARTING, null);
      emitEvent(FrontendEvents.USER_APP_RUN_CLICKED);
      setAppRestarting(true);
      resetSocketConnectionStateForInstance(instance.id);
      startInstance(
        instance.id,
        builderSessionStateId,
        ramMb || undefined,
        autoUpdateTestApp || false
      )
        .then(async (result) => {
          if (result.pre_check_errors) {
            updateAppState(AppInstanceState.ENDED_WITH_ERROR, null);
            const ttyMessage: TTYMessage = {
              timestamp: new Date().toISOString(),
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              id: (uuidv4 as () => string)(),
              message: result.pre_check_errors,
              source: SOURCE_EXCEPTION,
            };

            const formattedMessage = await convertFromTTYToMessage(ttyMessage);
            if (formattedMessage) {
              messageHook([formattedMessage], null, false);
            }
            emitEvent(FrontendEvents.USER_APP_RUN_FAILED);
            setInstance({ ...instance, version_id: appVersion?.id || '' });
          } else {
            await loadSession();
            emitEvent(FrontendEvents.USER_APP_RUN_SUCCESSFULLY);
            return true;
          }
          return false;
        })
        .finally(() => {
          setAppRestarting(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          emitEvent(FrontendEvents.USER_APP_RUN_FAILED);
          toast.error('There was an error starting the app.');
          setError('There was an error starting the app. Please refresh the page and try again.');
          // If the request failed, we don't know if anything actually happened to the
          // instance. Reset the state to the last one sent by the backend (this has
          // no effect if a state was already updated from the websocket after we set it
          // to STARTING above).
          updateAppState(backendAppState, null);
        });
    }
  };

  const updateAppInstance = (
    builderSessionStateId = '',
    ramMb?: number,
    autoUpdateTestApp?: boolean
  ) => {
    if (instance) {
      const currentAppRam = ramMb || useAppStore.getState().currentAppRam || undefined;
      const currentAppAutoUpdateTestApp =
        autoUpdateTestApp || useAppStore.getState().autoUpdateTestApp;
      setAppRestarting(true);
      updateAppState(AppInstanceState.STOPPING, null);
      stopAppInstance()
        .then(() => {
          startAppInstance(builderSessionStateId, currentAppRam, currentAppAutoUpdateTestApp);
          return true;
        })
        .catch(() => {
          emitEvent(FrontendEvents.USER_APP_RUN_RESTART_FAILED);
          toast.error('There was an error restarting the app');
          // If the request failed, we don't know if anything actually happened to the
          // instance. Reset the state to the last one sent by the backend.
          updateAppState(backendAppState, null);
        });
    }
  };

  const formatDisplayedAppStatus = (input: string): string => {
    const words = input.split('_');

    if (words.length > 0 && words[0] === 'starting') {
      return 'Deploying';
    }

    if (words.length > 0 && words[0] === 'installing') {
      return 'Installing';
    }

    // Capitalize the first letter of each word and join them
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // eslint-disable-next-line max-lines-per-function
  const messageHookStatusUpdates = (message: PingClientParams) => {
    const isInsideIframe = window.self !== window.top;
    if (message.app_chat_state_update) {
      if (isInsideIframe) {
        const actionsToParent: ActionToRunAVersionOfTheApp = {
          builderSessionStateId: '',
          isFromTestingInstance: isTestingApp,
          appChatUpdateState: JSON.stringify(message.app_chat_state_update),
        };
        window.parent.postMessage(actionsToParent, '*');
      }
    }
    if (message.instance_info) {
      setInstance(message.instance_info);
    }
    if (message.state) {
      const state: AppInstanceState = message.state as unknown as AppInstanceState;
      // eslint-disable-next-line no-console
      console.info(`App status update message received: ${state}`);
      const wasAppStoppedPreviously = isStateOfStoppedApp(appInstanceState.current);
      const isAppStoppedNow = isStateOfStoppedApp(state);
      updateBackendAppState(state, new Date(message.timestamp_change));

      // subscribeToAppInstanceTty only retrieves the messages from logs after a
      // websocket connection closes. If the app started and ended too quickly, it
      // may not have noticed the app was running (which it does by checking the state
      // periodically) and never tried opening a websocket.
      // So, in order to ensure logs from quick-running apps are retrieved, we also
      // retrieve them here when the ping websocket indicates that the app stopped.
      if (
        !wasAppStoppedPreviously &&
        isAppStoppedNow &&
        instance &&
        !appRestartInProgress.current &&
        !hasFetchedFromLogsAlready.current
      ) {
        catchUpWithPreviousMessagesSavedOnLogs(
          instance.id as unknown as string,
          messageHookForMessagesFromHistory,
          idsOfPreviouslyProcessedMessages,
          lastMessageFromHistoryFile
        )
          .then(() => {
            hasFetchedFromLogsAlready.current = true;
            return null;
          })
          .catch(() => toast.error('There was an error retrieving app messages'));
      }
    }

    if (message.current_version_updated) {
      updateAppVersion();
    }
  };

  const submitUserPrompt = (prompt: string) => {
    if (instance) {
      if (ttyWebsocket.current) {
        // eslint-disable-next-line no-void
        void sendUserMessageToApp(
          ttyWebsocket.current,
          prompt,
          idsOfPreviouslyProcessedMessages,
          messageHookForMessagesFromTty
        );
      }
    }
  };

  const loadApp = (instance?: LazyAppInstanceMinimal) => {
    if (instance) {
      return getApp(instance.app_id as unknown as string).then((app) => {
        if (app.app_icon_url) {
          const imageName = getImageName(app?.app_icon_url);
          fetchAppImage(imageName, 0, 3)
            .then((response) => {
              let appIcon = '';
              if (response) {
                appIcon = response;
              } else {
                appIcon = LazyAvatar as string;
              }
              setAppFavIcon(appIcon);
              return setAppImageUrl(appIcon);
            })
            .catch(() => {
              return setAppImageUrl(LazyAvatar as string);
            });
        }
        if (app?.name) {
          setAppName(app?.name);
        }
        return setApp(app);
      });
    }
  };

  const loadMoreMessages = async () => {
    if (instance?.id) {
      const messagesLoaded = await catchUpWithPreviousMessages(
        instance.id,
        messageHookToLoadPreviousMessages,
        idsOfPreviouslyProcessedMessages,
        setOpenAppUrlFromMessages,
        setSqliteWebUrlFromMessages,
        false,
        timestampOfOldestLoadedMessage.current
      );
      if (!messagesLoaded) {
        setIsAllPreviousMessagesLoaded(true);
      }
    }
  };

  const hasIframePreview = !isMobileDevice() && openAppUrl && isRunning;

  const handleMessageFromParentWindow = (event: MessageEvent) => {
    if (event?.source === window.parent) {
      if (typeof (event?.data as ActionToRunAVersionOfTheApp).builderSessionStateId === 'string') {
        const actionsToRunAVersionOfTheApp = event?.data as ActionToRunAVersionOfTheApp;
        const builderSessionStateId = actionsToRunAVersionOfTheApp.builderSessionStateId;
        const ramMb =
          actionsToRunAVersionOfTheApp.currentAppRam === undefined
            ? instance?.extra_app_instance_params?.ram_mb || 0
            : actionsToRunAVersionOfTheApp.currentAppRam || 0;

        const autoUpdateTestApp =
          actionsToRunAVersionOfTheApp.autoUpdateTestApp === undefined
            ? instance?.extra_app_instance_params?.auto_update_test_app || false
            : actionsToRunAVersionOfTheApp.autoUpdateTestApp || false;

        const isNewInstanceSettings =
          ramMb !== useAppStore.getState().currentAppRam ||
          autoUpdateTestApp !== useAppStore.getState().autoUpdateTestApp;

        useAppStore.setState({
          currentAppRam: ramMb,
          autoUpdateTestApp: autoUpdateTestApp,
        });

        if (appInstanceState.current === AppInstanceState.RUNNING) {
          if (
            (builderSessionStateId
              ? builderSessionStateIdOfInstance.current !== builderSessionStateId
              : isNewVersionAvailableRef.current) ||
            isNewInstanceSettings
          ) {
            updateAppInstance(builderSessionStateId, ramMb, autoUpdateTestApp);
          }
        } else {
          startAppInstance(builderSessionStateId, ramMb, autoUpdateTestApp);
        }
      }
    } else if (typeof (event?.data as ActionForParentWindow).submitPrompt === 'string') {
      const isInsideIframe = window.self !== window.top;
      if (isInsideIframe) {
        window.parent.postMessage(event.data, '*');
      } else {
        if (app) {
          const url = `/apps/${app?.id}/build?prompt=${encodeURIComponent(
            (event?.data as ActionForParentWindow).submitPrompt as string
          )}`;
          window.open(url, '_blank');
        }
      }
    }
  };

  const handleSaveInstanceSettings = () => {
    updateAppInstance(
      '',
      useAppStore.getState().currentAppRam || undefined,
      useAppStore.getState().autoUpdateTestApp
    );
    if (isTestingApp) {
      const actionsToParent: ActionToRunAVersionOfTheApp = {
        builderSessionStateId: '',
        autoUpdateTestApp: useAppStore.getState().autoUpdateTestApp,
        currentAppRam: useAppStore.getState().currentAppRam,
        isFromTestingInstance: isTestingApp,
      };
      window.parent.postMessage(actionsToParent, '*');
    }
  };

  // -------
  // Effects
  // -------

  useEffect(() => {
    appRestartInProgress.current = isAppRestarting;
  }, [isAppRestarting]);

  // eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    if (instance?.id) {
      if (lastSubscribedTTYInstance.current !== instance.id) {
        lastSubscribedTTYInstance.current = instance.id;

        new Promise((resolve) => {
          if (
            instance?.state_info?.state !== AppInstanceState.RUNNING &&
            !hasFetchedFromLogsAlready.current
          ) {
            // only extract initial history if app is not already running
            resolve(
              catchUpWithPreviousMessagesSavedOnLogs(
                instance.id as unknown as string,
                messageHookForMessagesFromHistory,
                idsOfPreviouslyProcessedMessages,
                lastMessageFromHistoryFile,
                false
              )
            );
            hasFetchedFromLogsAlready.current = true;
          } else {
            resolve(null);
          }
        })
          // eslint-disable-next-line max-lines-per-function
          .then(() => {
            const params: SubscribeToAppInstanceTtyParams = {
              instanceId: instance.id,
              messageHook: messageHookForMessagesFromTty,
              messageHookForMessagesFromHistory,
              idsOfPreviouslyProcessedMessages,
              lastMessageFromHistoryFile,
              connectHook: (ws) => {
                ttyWebsocket.current = ws;
              },
              shouldAttemptToConnect: () => {
                return shouldAttemptToConnect.current;
              },
              setOpenAppUrlFromMessages,
              setSqliteWebUrlFromMessages,
              onReachingMaxConnectionAttempts: setAppsMessageLoadingFailedError,
              updateAppState: () => {},
              lastMessageTimestamp,
              onDisconnected: () => {
                if (
                  appInstanceState.current !== AppInstanceState.STARTED &&
                  appInstanceState.current !== AppInstanceState.INSTALLING_DEPENDENCIES &&
                  appInstanceState.current !== AppInstanceState.RUNNING
                ) {
                  shouldAttemptToConnect.current = false;
                }
              },
              ttyCapabilities,
            };

            const paramsAppStateSocket: SubscribeToAppInstanceUpdatesParams = {
              instanceId: instance.id,
              messageHook: messageHookStatusUpdates,
              connectHook: (ws) => {
                appStateWebsocket.current = ws;
              },
            };
            subscribeToAppInstanceUpdates(paramsAppStateSocket);
            // eslint-disable-next-line no-void, @typescript-eslint/no-floating-promises
            subscribeToAppInstanceTty(params);
            return null;
          })
          .finally(() => {
            if (
              instance.state_info === null || // case when its a new app without any instance
              instance.state_info?.state !== AppInstanceState.RUNNING
            ) {
              // This app was never started before
              setIsAppStatusKnown(true);
            } else {
              // Add a safeguard in case history file is missing
              // or app instance state is out of sync due to container change
              setTimeout(() => setIsAppStatusKnown(true), 10000);
            }
          })
          .catch(() => toast.error('There was an error loading the logs.'));
      }

      return () => {
        closeTtyWebsocket();
        closeAppStateWebsocket();
      };
    }
  }, [instance?.id]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void loadVerifiedCustomDomain(app?.id);
  }, [app]);

  useEffect(() => {
    if (app?.name) {
      document.title = app?.name;
    }
  }, [app?.name]);

  useEffect(() => {
    // Important! Update the cached values of height/width of app run screen
    // Otherwise screen width will be 0 and no messages will appear
    updateCachedValues();
  }, [messages]);

  useEffect(() => {
    setUserInputBlocked(true);
    disableRevertFunction();
    // Don't show the stop button for the running-app loading bar, as it doesn't
    // do anything.
    setHideLoadingStop(true);

    loadSession()
      .then(async (instance) => {
        await loadApp(instance);
        await loadVerifiedCustomDomain(app?.id);
        updateAppState(instance?.state_info?.state || appState, null);
        return instance;
      })
      .catch((e) => {
        if (e instanceof ApiError && (e.status === 403 || e.status === 404)) {
          setInitialLoadingError('Session not found or not accessible by the current user.');
        } else {
          setInitialLoadingError('There was an error loading this app. Please refresh the page.');
        }
      });
  }, []);

  useEffect(() => {
    const versionMismatch =
      instance != null &&
      appVersion != null &&
      appVersion.id !== instance?.version_id &&
      appState !== AppInstanceState.NEVER_RUN;

    setIsNewVersionAvailable(versionMismatch);
    isNewVersionAvailableRef.current = versionMismatch;
  }, [appState, instance?.version_id, appVersion?.id]);

  const useRetryFetch = (
    fetchFunction: () => Promise<void>,
    shouldFetch: boolean,
    dependencies: any[]
  ) => {
    useEffect(() => {
      let retries = 0;
      let currentInterval = 100;

      const handleAttemptFailure = () => {
        retries++;
        if (retries <= MAX_RETRIES_TO_CHECK_FOR_APP_LINK_TO_BE_ACCESSIBLE) {
          setTimeout(() => {
            // eslint-disable-next-line no-void
            void fetchFunction().catch(handleAttemptFailure);
          }, currentInterval);
          currentInterval *= 2; // Increase the interval for exponential backoff
        } else {
          setOpenAppUrl(null);
        }
      };

      const executeFetch = async () => {
        try {
          await fetchFunction();
        } catch (error) {
          handleAttemptFailure();
        }
      };

      if (shouldFetch) {
        // eslint-disable-next-line no-void
        void executeFetch(); // Using void to explicitly ignore the returned promise
      }
    }, dependencies);
  };

  useEffect(() => {
    setIsTestingApp(window.location.href.includes('/test_instance/') ? true : false);
  }, []);

  // Usage in your component

  useRetryFetch(
    async () => {
      try {
        const isAccessible = app ? await checkAppLinkAccessibility(app?.id, testing) : null;
        if (isAccessible) {
          setOpenAppUrl(openAppUrlFromMessages);
        } else {
          throw new Error('Failed to verify app accessibility');
        }
      } catch (e) {
        // eslint-disable-next-line
        if (e?.status === 404) {
          // if endpoint does not exist on backend, fallback to client side probing
          const response = await fetch(openAppUrlFromMessages as string);
          if (response.status >= 200 && response.status <= 399) {
            setOpenAppUrl(openAppUrlFromMessages);
          } else {
            throw new Error('Failed to fetch');
          }
        } else {
          throw e;
        }
      }
    },
    Boolean(openAppUrlFromMessages && (verifiedCustomDomain === '' || testing)),
    [openAppUrlFromMessages, verifiedCustomDomain]
  );

  useRetryFetch(
    async () => {
      const verified = app ? await checkVerifiedCustomDomainAccessibility(app?.id) : null;
      if (verified) {
        setOpenAppUrl(verified);
      } else {
        throw new Error('Failed to verify domain accessibility');
      }
    },
    Boolean(verifiedCustomDomain && app && !testing),
    [verifiedCustomDomain, app]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessageFromParentWindow);

    return () => {
      window.removeEventListener('message', handleMessageFromParentWindow);
    };
  }, [instance?.id, app?.id]);

  useEffect(() => {
    builderSessionStateIdOfInstance.current =
      instance?.version?.artifacts?.content?.builder_session_state_id || null;
  }, [instance?.version?.artifacts?.content?.builder_session_state_id]);

  // -------------------
  // Rendering functions
  // -------------------

  const renderAppIcon = () =>
    app?.app_icon_url && (
      <div className="h-20 w-20 rounded-md block">
        <ImageLoader src={app?.app_icon_url} maxTrials={5} isIcon={true}></ImageLoader>
      </div>
    );

  const renderNewAppContent = () => (
    <div className="w-full h-screen flex flex-1 items-center justify-center">
      <div
        className={classNames('flex gap-4 flex-col p-3 items-center', {
          'gap-3 p-2': isMobileDevice(),
        })}
      >
        {renderAppIcon()}
        <div
          className={classNames('font-semibold items-center text-center', {
            'text-3xl': isMobileDevice(),
            'text-5xl': !isMobileDevice(),
          })}
        >
          {app?.name}
        </div>
        <div className="text-gray-400 text-sm text-center">{app?.description}</div>
        <RunAppButton
          isAppRestarting={isAppRestarting}
          onRunApp={(ramMb: number, autoUpdateTestApp: boolean) => {
            startAppInstance('', ramMb, autoUpdateTestApp);
          }}
          leftAlignDropDown={false}
          currentRam={instance?.extra_app_instance_params?.ram_mb}
          isTestingApp={isTestingApp}
          handleSaveInstanceSettings={handleSaveInstanceSettings}
        />
        {!isTestingApp && <PublishCostWarning />}
      </div>
    </div>
  );

  const renderAppLogs = (renderHeader = false) => (
    <div className="flex h-full overflow-hidden bg-white rounded-lg">
      <div className="flex flex-col h-full w-full overflow-y-scroll">
        <AppRunContext.Provider
          value={{
            isNewVersionAvailable,
            appId: instance?.app_id,
            instanceId: instance?.id,
            isRunning,
            isAppRunMode: true,
          }}
        >
          {renderHeader && (
            <div className="flex w-full justify-start items-center px-4 py-2">
              <span>App Logs</span>
            </div>
          )}
          <MessageListRenderer
            loadMoreMessages={loadMoreMessages}
            isAllPreviousMessagesLoaded={isAllPreviousMessagesLoaded}
            selectedTabIndex={() => {}}
            isAppRun={true}
          />
        </AppRunContext.Provider>
      </div>
    </div>
  );

  const renderAppHeader = () => (
    <div className="flex flex-row p-2" ref={appStatusRef}>
      <div className="flex flex-1 justify-between items-center">
        <div className="flex gap-1">
          <span
            data-testid={TOP_BAR_APP_RUNNING_STATE}
            className={classNames('text-sm capitalize', {
              'text-label-secondary': appState !== AppInstanceState.RUNNING,
              'text-system-success': appState === AppInstanceState.RUNNING,
            })}
          >
            {formatDisplayedAppStatus(appState)}
          </span>
          {testing && appState === AppInstanceState.RUNNING && instance?.id && (
            <AppAutoShutDownTimer instanceId={instance.id} />
          )}
        </div>
        <AppInstanceStatusDisplay
          state={appState}
          isRestarting={isAppRestarting}
          isStopping={isAppStopping}
          isRunning={appState === AppInstanceState.RUNNING}
          isNewVersionAvailable={isNewVersionAvailable}
          onStartApp={(ramMb: number, autoUpdateTestApp) =>
            startAppInstance('', ramMb, autoUpdateTestApp)
          }
          currentRam={instance?.extra_app_instance_params?.ram_mb}
          isTestingApp={isTestingApp}
          onStopApp={() =>
            // eslint-disable-next-line no-void
            void stopAppInstance()
          }
          openAppUrl={openAppUrlFromMessages}
          sqliteWebUrl={sqliteWebUrlFromMessages}
          emitEvent={emitEvent}
          handleSaveInstanceSettings={handleSaveInstanceSettings}
        />
      </div>
    </div>
  );

  const renderProductionAppRunHeader = () => {
    const hideConfigureCustomDomainButton =
      process.env.REACT_APP_FLAG_ENABLE_CUSTOM_DOMAIN !== 'true';

    return (
      <div className="flex flex-row p-2">
        <div className="flex flex-1 justify-between items-center">
          <Button
            className={classNames('bg-black/[0.03] text-label-primary gap-1')}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              window.location.href = `/apps/${app?.id as string}/build?tab=1`;
            }}
            iconProps={{ icon: ArrowLeftIcon, iconSize: 24 }}
          >
            {!isMobileDevice() && 'Back to builder'}
          </Button>
          <div className="flex gap-1">
            <RocketIcon className="text-system-success" size={20} />
            <span className="font-medium">Production version</span>
          </div>

          <Button
            className={classNames('bg-purple-500 text-white hover:bg-purple-500/80', {
              '!cursor-default bg-white/[0] text-white/[0]': hideConfigureCustomDomainButton,
            })}
            onClick={() => setIsCustomDomainModalOpen(true)}
            disabled={hideConfigureCustomDomainButton}
            iconProps={{ icon: GlobalIcon, iconSize: 20 }}
          >
            {!isMobileDevice() && 'Configure custom domain'}
          </Button>
        </div>
      </div>
    );
  };

  const renderIframeWithLogs = () => (
    <Split
      horizontal
      minPrimarySize="25%"
      initialPrimarySize="75%"
      minSecondarySize="15%"
      splitterSize="12px"
      renderSplitter={() => (
        <div className="flex w-full justify-center items-center h-3 bg-background-secondary">
          <img src={DragIcon as string} alt="Drag Icon" className="pointer-events-none" />
        </div>
      )}
    >
      <BrowserFrame
        url={verifiedCustomDomain || openAppUrlFromMessages || ''}
        displayIframe={!!openAppUrl}
        emitEvent={emitEvent}
      />

      {renderAppLogs(true)}
    </Split>
  );

  if (!userPermissions?.isUserAllowedToRunApp) {
    return (
      <div className="h-screen w-screen">
        <UpgradeToProNotice />
      </div>
    );
  }

  if (!isAppStatusKnown || initialLoadingError) {
    return <LoadingPage error={initialLoadingError} />;
  }

  if (appState === AppInstanceState.NEVER_RUN) {
    return renderNewAppContent();
  }

  return (
    <div data-instance-id={instance?.id} className="w-full h-screen bg-background-secondary">
      <div
        className={classNames('grid col-auto grid-rows-1 justify-items-center w-full h-full', {
          'py-0': isMobileDevice(),
        })}
      >
        <div className="flex flex-col">
          {!testing && renderProductionAppRunHeader()}
          {isCustomDomainModalOpen && app && (
            <CustomDomainsModal app={app} onHide={() => setIsCustomDomainModalOpen(false)} />
          )}
          {isNewVersionAvailable && !isAppRestarting && (
            <UpdateAppHeader
              onUpdate={() => updateAppInstance()}
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              versionCreationDate={instance?.version?.artifacts?.sent_at as string}
            />
          )}
          {!testing && appState === AppInstanceState.RUNNING && (
            <PublishedAppRunningCostNotification ramMb={instance?.extra_app_instance_params?.ram_mb || 512} />
          )}
          <div className="flex flex-col w-screen h-full overflow-auto no-scrollbar px-4">
            {renderAppHeader()}
            {hasIframePreview && appState === AppInstanceState.RUNNING
              ? renderIframeWithLogs()
              : renderAppLogs()}
          </div>
        </div>
        <div
          className={classNames('max-w-screen-md w-screen', {
            'px-12': !isMobileDevice(),
            'px-3': isMobileDevice(),
          })}
        >
          <Chat
            isAppRun={true}
            shouldFocus={true}
            submitUserPrompt={submitUserPrompt}
            handleSaveInstanceSettings={handleSaveInstanceSettings}
          />
        </div>
      </div>
    </div>
  );
};

export default AppRun;
