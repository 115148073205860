import { create } from 'zustand';

import { Message } from '../types';
import {
  CancelablePromise,
  ChatSessionBuilder,
  LazyApp,
  BuilderSessionState,
  ContentBuilderMessageGroup,
  Persona,
  LazyAppInstanceMinimal,
} from '../api/generated';
import { RichPromptAction } from '../components/chat/RichPromptActionList';
import { isMobileDevice } from '../utils/deviceDimensions';
import { BuilderSessionStateUpdateEvent, BuilderSessionUpdateRequest } from '../api/BuilderApi';
import { contentBuilderMessageGroupToMessage } from '../api/transform';
import { BuilderInteractionMode } from '../api/generated/models/BuilderInteractionMode';

export const LOGS_LOADING_ERROR_MESSAGE = 'There was an error loading the logs.';

export const userPromptToMessages = (prompt: string) => {
  // This message is currently transient and meant only as a
  // way to show immediate feedback to the user
  if (prompt) {
    const contentBuilderMessageGroup: ContentBuilderMessageGroup = {
      role: Persona.BUILDER,
      content: {
        text: {
          text_language: 'en',
          text: prompt,
        },
      },
    };
    const message = contentBuilderMessageGroupToMessage(contentBuilderMessageGroup, []);
    return [message];
  }

  return [];
};

const builderSessionStateToMostRecentChatMessage = (
  builderSessionState: BuilderSessionState
): Message => {
  const mostRecentContentBuilderMessageGroup =
    builderSessionState.state.content_builder.slice(-1)[0];
  return contentBuilderMessageGroupToMessage(mostRecentContentBuilderMessageGroup, []);
};

export type RevertFunction = null | ((id: string) => Promise<void>);
export type ViewVersionFunction = null | ((id: string) => void);
export type TestVersionFunction = null | ((id: string) => void);

interface ChatStoreState {
  showMoveAppToOrgModalForApp: LazyApp | null;
  showMoveAppToFolderModalForApp: LazyApp | null;
  showAbilitiesIframe: boolean;
  instance: LazyAppInstanceMinimal | null;
  messages: Message[];
  currentUserPrompt: string;
  currentUserPromptIsEmpty: boolean;
  currentBuilderSessionStateId: string;
  currentBuilderInteractionMode: BuilderInteractionMode;

  isUserEditingMessage: boolean;
  editedMessageBuilderSessionStateId: string;

  userInputBlocked: boolean;
  userInputLoading: boolean;
  userInputFocused: boolean;
  userClickedStop: boolean;
  isViewingVersion: boolean;
  isMemorySelectorDropdownOpen: boolean;
  isTestApp: boolean;
  revertFunction: RevertFunction;
  viewVersionFunction: ViewVersionFunction;
  testVersionFunction: TestVersionFunction;

  hideChatMessages: boolean;

  error: string;
  availableActions: RichPromptAction[];
  statusMessage: string | null;
  viewedMessageId: string;

  isUserSendingPrompt: boolean;

  submitUserPrompt: (
    prompt: string,
    builderSessionUpdateRequest: BuilderSessionUpdateRequest,
    isLlmIndependentRequest: boolean,
    emitResponseReceivedEvent?: (correlationId) => void
  ) => Promise<boolean>;
  submitUserPromptSetInitialState: (
    prompt: string,
    builderSessionUpdateRequest: BuilderSessionUpdateRequest
  ) => void;
  submitUserPromptHandleStreamingUpdates: (
    prompt: string,
    builderSessionUpdateRequest: BuilderSessionUpdateRequest
  ) => void;
  handleUserRequestedCancellation: () => void;
  handleAppInstallingDependenciesMessage: () => void;
  handleAppStartingMessage: () => void;
  handleAppStoppingMessage: () => void;
  handleAppRunningMessage: () => void;
  handleAppNotRunningMessage: () => void;
  setLoadingBarWithMessage: (message: string) => void;
  setStatusMessage: (statusMessage: string) => void;
  setInstance: (instance: LazyAppInstanceMinimal) => void;
  setUserInputLoading: (isLoading: boolean) => void;
  resetError: () => void;
  setError: (error: string) => void;
  setHideLoadingStop: (isLoadingStop: boolean) => void;
  setUserInputBlocked: (isBlocked: boolean) => void;
  setMessages: (messages: Message[]) => void;
  setAvailableActions: (availableActions: RichPromptAction[]) => void;
  appendMessages: (messages: Message[]) => void;
  prependMessages: (messages: Message[]) => void;
  setAppsMessageLoadingFailedError: () => void;
  disableRevertFunction: () => void;
  inFlightPrompt: CancelablePromise<any> | null;
  hideLoadingStop: boolean;
  appChatSession: ChatSessionBuilder | undefined;
}

// eslint-disable-next-line max-lines-per-function
export const useChatStore = create<ChatStoreState>((set, get) => ({
  showMoveAppToOrgModalForApp: null,
  showMoveAppToFolderModalForApp: null,
  showAbilitiesIframe: false,
  instance: null,
  messages: [],
  availableActions: [],
  statusMessage: null,
  currentUserPrompt: '',
  currentUserPromptIsEmpty: true,
  userInputBlocked: false,
  userInputLoading: false,
  userInputFocused: false,
  inFlightPrompt: null,
  userClickedStop: false,
  isViewingVersion: false,
  hideChatMessages: false,
  revertFunction: null,
  viewVersionFunction: null,
  testVersionFunction: null,
  error: '',
  hideLoadingStop: false,
  isMemorySelectorDropdownOpen: false,
  isTestApp: false,
  appChatSession: undefined,
  viewedMessageId: '',
  isUserSendingPrompt: false,
  currentBuilderSessionStateId: '',
  currentBuilderInteractionMode: BuilderInteractionMode.CHAT,
  isUserEditingMessage: false,
  editedMessageBuilderSessionStateId: '',

  disableRevertFunction: () => {
    set(() => ({ revertFunction: null }));
  },

  setMessages: (messages: Message[]) => {
    // Keep only the last x messages. The browser was hanging when the number
    // of messages was too large.
    set(() => ({ messages: messages.slice(-10000) }));
  },

  appendMessages: (messages: Message[]) => {
    get().setMessages([...get().messages, ...messages]);
  },

  setAppsMessageLoadingFailedError: () => {
    set({
      error: LOGS_LOADING_ERROR_MESSAGE,
      userInputLoading: false,
      hideLoadingStop: true,
    });
  },

  prependMessages: (messages: Message[]) => {
    get().setMessages([...messages, ...get().messages]);
  },

  setStatusMessage: (statusMessage: string) => {
    set(() => ({ statusMessage }));
  },

  setInstance: (instance: LazyAppInstanceMinimal) => {
    set(() => ({ instance }));
  },

  setAvailableActions: (availableActions: RichPromptAction[]) => {
    set(() => ({ availableActions }));
  },

  setUserInputLoading: (isLoading: boolean) => {
    set(() => ({ userInputLoading: isLoading }));
  },

  setUserInputBlocked: (isBlocked: boolean) => {
    set(() => ({ userInputBlocked: isBlocked }));
  },

  resetError: () => {
    set(() => ({ error: '', hideLoadingStop: false }));
  },

  setError: (error: string) => {
    set(() => ({ error }));
  },

  setHideLoadingStop: (isLoadingStop: boolean) => {
    set(() => ({ hideLoadingStop: isLoadingStop }));
  },

  setLoadingBarWithMessage: (message: string) => {
    set(() => ({
      userInputLoading: true,
      statusMessage: message,
    }));
  },

  handleAppStartingMessage: () => {
    const APP_STARTING_STATUS_MESSAGE = 'Deploying to cloud...';
    get().setLoadingBarWithMessage(APP_STARTING_STATUS_MESSAGE);
  },

  handleAppRunningMessage: () => {
    set(() => ({
      userInputLoading: false,
      statusMessage: null,
    }));
  },

  handleAppInstallingDependenciesMessage: () => {
    const APP_INSTALLING_DEPENDENCIES_STATUS_MESSAGE = 'Installing dependencies...';
    get().setLoadingBarWithMessage(APP_INSTALLING_DEPENDENCIES_STATUS_MESSAGE);
  },

  handleAppStoppingMessage: () => {
    const APP_STOPPING_STATUS_MESSAGE = 'Stopping...';
    get().setLoadingBarWithMessage(APP_STOPPING_STATUS_MESSAGE);
  },

  handleAppNotRunningMessage: () => {
    // If the app is not running, don't show the loading bar or the input text box.
    set(() => ({
      userInputLoading: false,
      userInputBlocked: true,
      statusMessage: null,
    }));
  },

  handleUserRequestedCancellation: () => {
    const currentInFlightPrompt = get().inFlightPrompt;
    if (currentInFlightPrompt !== null && !currentInFlightPrompt.isCancelled) {
      currentInFlightPrompt.cancel();
    }
    set(() => ({ inFlightPrompt: null, userInputLoading: false, userClickedStop: true }));
  },

  submitUserPrompt: async (
    prompt: string,
    builderSessionUpdateRequest: BuilderSessionUpdateRequest,
    isLlmIndependentRequest: boolean,
    emitResponseReceivedEvent?: (correlationId) => void
  ): Promise<boolean> => {
    const previousMessages = [...get().messages];
    get().submitUserPromptSetInitialState(prompt, builderSessionUpdateRequest);
    get().submitUserPromptHandleStreamingUpdates(prompt, builderSessionUpdateRequest);
    try {
      const result = await builderSessionUpdateRequest.request;
      if (!isLlmIndependentRequest && emitResponseReceivedEvent) {
        emitResponseReceivedEvent(
          result.state.content_builder.slice(-1)[0]?.content?.metadata?.correlation_id
        );
      }
      set(() => ({ currentUserPrompt: '' }));
    } catch (e: unknown) {
      const error = e as Error | undefined;
      if (error && error.name === 'CancelError') {
        // since user explicitly cancelled the request, reset to previous chat history
        set(() => ({ messages: previousMessages }));

        return false;
      } else {
        if (isMobileDevice()) {
          location.reload();
        }
        return true;
      }
    } finally {
      set(() => ({ userInputLoading: false, inFlightPrompt: null }));
    }
    return true;
  },

  submitUserPromptSetInitialState: (
    prompt: string,
    builderSessionUpdateRequest: BuilderSessionUpdateRequest
  ) => {
    set(() => ({
      error: '',
      userInputLoading: true,
      userClickedStop: false,
      currentUserPrompt: prompt,
      messages: [...get().messages, ...userPromptToMessages(prompt)],
      inFlightPrompt: builderSessionUpdateRequest.request,
    }));
  },

  submitUserPromptHandleStreamingUpdates: (
    prompt: string,
    builderSessionUpdateRequest: BuilderSessionUpdateRequest
  ) => {
    const messagesWithUserPrompt = [...get().messages, ...userPromptToMessages(prompt)];
    if (builderSessionUpdateRequest.requestStreamEventTarget) {
      builderSessionUpdateRequest.requestStreamEventTarget.addEventListener(
        BuilderSessionStateUpdateEvent.type,
        (event: BuilderSessionStateUpdateEvent<BuilderSessionState>) => {
          const builderSessionState = event.detail;
          const mostRecentChatMessage: Message =
            builderSessionStateToMostRecentChatMessage(builderSessionState);
          set(() => ({
            messages: [...messagesWithUserPrompt, mostRecentChatMessage],
          }));
        }
      );
    }
  },
}));
