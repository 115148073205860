import EditIcon from 'remixicon-react/Edit2LineIcon';
import React from 'react';
import { useChatStore } from '../../store/chat';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { EDIT_MESSAGE_BUTTON, SOUNDS_GOOD_LETS_BUILD_IT_PROMPT } from '../../constants';
import { BuilderInteractionMode } from '../../api/generated/models/BuilderInteractionMode';

interface EditMessageButtonProps {
  previousMessageBuilderSessionStateId?: string;
  messageContent: string;
  currentMessageBuilderSessionStateId?: string;
}
// eslint-disable-next-line max-lines-per-function
const EditMessageButton = ({
  previousMessageBuilderSessionStateId,
  messageContent,
  currentMessageBuilderSessionStateId,
}: EditMessageButtonProps) => {
  const buttonText = isMobileDevice() ? '' : 'Edit';
  const handleEditUserMessage = () => {
    const currentMessageBuilderSessionState = useChatStore
      .getState()
      .appChatSession?.content_builder.find(
        (contentBuilder) =>
          contentBuilder.content.builder_session_state_id === currentMessageBuilderSessionStateId
      );

    const builderInteractionMode = messageContent.startsWith(SOUNDS_GOOD_LETS_BUILD_IT_PROMPT)
      ? BuilderInteractionMode.BUILD
      : currentMessageBuilderSessionState?.content.interaction_mode ??
        useChatStore.getState().currentBuilderInteractionMode;

    useChatStore.setState({
      currentUserPrompt: messageContent,
      isUserEditingMessage: true,
      editedMessageBuilderSessionStateId: previousMessageBuilderSessionStateId,
      currentBuilderInteractionMode: builderInteractionMode,
    });
  };

  return (
    <div
      className={classNames('flex', {
        'justify-end': isMobileDevice(),
      })}
    >
      <button
        data-testid={EDIT_MESSAGE_BUTTON}
        onClick={handleEditUserMessage}
        disabled={useChatStore.getState().userInputLoading}
        className={classNames(
          'text-system-accent md:bg-transparent',
          'hover:bg-system-accent/10 rounded-lg text-lazy-btn-text p-1',
          'flex gap-1 mb-3 md:mr-0',
          {
            'cursor-not-allowed': useChatStore.getState().userInputLoading,
          }
        )}
      >
        <EditIcon
          className="align-text-bottom
        inline-block mr-1"
          size={16}
        />
        <span className="hidden xs:inline-block">{buttonText}</span>
      </button>
    </div>
  );
};

export default EditMessageButton;
