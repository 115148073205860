import { LazyApp, LazyAppVersion } from '../api/generated';

export function getEnumKeyByValue<T>(enumObject: T, value: string): keyof T | undefined {
  for (const key in enumObject) {
    if (enumObject[key] === value) {
      return key as keyof T;
    }
  }
  return undefined;
}

export function getLinkToTemplate(templateApp: LazyApp): string {
  return (
    `${process.env.REACT_APP_WEBFLOW_TEMPLATES_LINK as string}/` +
    `${templateApp.webflow_slug as string}`
  );
}

export function getLastPublishedVersionFromVersions(
  versions?: LazyAppVersion[]
): LazyAppVersion | null {
  const publishedVersions = (versions || [])
    .filter((v) => v.published)
    .map((v) => ({ dt: new Date(Date.parse(v.creation_date || '')), v }))
    .sort((a, b) => +b.dt - +a.dt)
    .map((vSort) => vSort.v);

  if (publishedVersions.length) {
    return publishedVersions[0];
  }

  return null;
}

export function hasElapsed(timestamp: Date | string | undefined, minutes = 20) {
  if (!timestamp) {
    return false;
  }
  const sentAt = typeof timestamp === 'string' ? new Date(timestamp) : timestamp;
  const currentTime = new Date();
  const timeDifference = currentTime.getTime() - sentAt.getTime(); // Get time in milliseconds

  const thresholdInMs = minutes * 60 * 1000;

  return timeDifference >= thresholdInMs;
}

export const SKELETON_PICKED_PROMPT = (pickedSkeleton: string) =>
  // eslint-disable-next-line max-len
  `I picked "${pickedSkeleton}" starting point, now propose the next feature that aligns with the previous messages.`;

export const isSkeletonPickedPrompt = (text: string): boolean => {
  const regex =
    // eslint-disable-next-line max-len
    /^I picked ".*" starting point, now propose the next feature that aligns with the previous messages\.$/;
  return regex.test(text);
};
