import React from 'react';
import WarningLineIcon from 'remixicon-react/AlarmWarningLineIcon';
import classNames from 'classnames';

export const PublishCostWarning = () => {
  return (
    <div
      className={classNames(
        'text-xs px-3 py-2 rounded-md m-4 font-semibold',
        'bg-white text-purple-500 border border-purple-500'
      )}
    >
      <div className="flex items-center gap-2">
        <WarningLineIcon className="text-purple-500" size={16} />
        <span>Note that running a published app incurs charges on daily basis.</span>
      </div>
    </div>
  );
};
