import React from 'react';
import WarningLineIcon from 'remixicon-react/AlarmWarningLineIcon';
import classNames from 'classnames';

const COST_PER_GB_PER_DAY = 1.56;

const calculateDailyCost = (ramMb: number): number => {
  const ramGb = ramMb / 1024;
  return Number((ramGb * COST_PER_GB_PER_DAY).toFixed(2));
};

export const PublishedAppRunningCostNotification = ({ ramMb }: { ramMb: number }) => {
  return (
    <div
      className={classNames(
        'mx-4 px-4 py-2 flex justify-center items-center gap-2 rounded-lg',
        'bg-white text-purple-500 border border-purple-500'
      )}
    >
      <WarningLineIcon className="text-white" size={18} />
      <span className="text-xs font-semibold ">
        This app is costing ${calculateDailyCost(ramMb)} for daily usage.
        <button
          onClick={() => window.open('https://accounts.getlazy.ai/usage', '_blank')}
          className={classNames(
            'bg-purple-500 text-white font-semibold hover:bg-white-100',
            'px-2 py-1 rounded text-xs ml-3'
          )}
        >
          View Usage Costs
        </button>
      </span>
    </div>
  );
};
