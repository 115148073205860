import React, { useEffect, useRef, useState } from 'react';
import { Message, MessageSourceType, TextMessageContent } from '../../types';
import MessageGroup from './MessageGroup';
import RevertMessageButton from './RevertMessageButton';
import { Persona, StarterPrompt } from '../../api/generated';
import {
  RevertFunction,
  ViewVersionFunction,
  TestVersionFunction,
  useChatStore,
} from '../../store/chat';
import { useAuthStore } from '../../store/auth';
import { ReactComponent as LazyScrollDown } from '../../assets/icons/lazy-scroll-down.svg';
import classNames from 'classnames';
import ViewVersionButton from './ViewVersionButton';
import PublishVersionButton from './PublishVersionButton';
import TestVersionButton from './TestVersionButton';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { isSkeletonPickedPrompt } from '../../utils';
import StarterPrompts from './StarterPrompts';
import { useSearchParams } from 'react-router-dom';
import {
  APPLIED_MANUAL_CODE_CHANGES,
  BUILDER_SELECTED_SKELETON_PREFIX,
  TabBarItemsIndex,
} from '../../constants';
import ChatActionsForUser from './ChatActionsForUser';
import { File } from '../../api/generated/models/File';

interface MessageListProps {
  starterPrompts?: StarterPrompt[];
  submitStarterPrompt?: (starterPrompt: string) => void;
  showExploreTemplates?: boolean;
  messages: Message[];
  mainContainerElementRef?: React.RefObject<HTMLDivElement>;
  revertFunction?: RevertFunction;
  viewAppVersionFunction?: ViewVersionFunction;
  testVersionFunction?: TestVersionFunction;
  onPublishVersion?: (string) => void;
  scrollDownButtonPb?: number;
  handleChatContainerScrolling?: () => void;
  isAllPreviousMessagesLoaded: boolean;
  loadMoreMessages: () => Promise<void>;
  activeBuilderSessionStateId?: string | null;
  isTemplate?: boolean;
  isAppRun?: boolean;
  selectedTabIndex: React.Dispatch<React.SetStateAction<TabBarItemsIndex>>;
  updateFiles?: (updatedFiles: File[]) => Promise<void>;
}

// eslint-disable-next-line max-lines-per-function
const MessageList = (props: MessageListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const endOfChatElement = useRef<HTMLSpanElement>(null);
  const [groupedMessages, setGroupedMessages] = useState<Message[][]>([]);

  const [isScrollDownVisible, setScrollDownVisible] = useState(false);

  const { viewedMessageId } = useChatStore();
  const { userPermissions } = useAuthStore();

  useEffect(() => {
    const messageGroup = props.messages.reduce((result: Message[][], message) => {
      if (result.length === 0) {
        result.push([message]);
      } else {
        const lastGroup = result[result.length - 1];
        const lastMessage = lastGroup[lastGroup.length - 1];

        if (
          lastMessage.source.type === message.source.type &&
          lastMessage.source.name === message.source.name
        ) {
          lastGroup.push(message);
        } else {
          result.push([message]);
        }
      }

      return result;
    }, [] as Message[][]);
    if (!props.isAllPreviousMessagesLoaded && !props.isAppRun) {
      // skips the first message group in case of load previous messages button.
      // so if the user clicked on  view code on the first message after load previous messages.
      // code comparison can work normally.
      setGroupedMessages(messageGroup.slice(1));
    } else {
      setGroupedMessages(messageGroup);
    }
  }, [props.messages]);

  const handleScrollDown = (isAutoScrolled = false) => {
    (props.mainContainerElementRef || containerRef).current?.scrollTo({
      top: containerRef.current?.scrollHeight,
      behavior: isAutoScrolled ? 'instant' : 'smooth',
    });
  };

  const isEditableMessage = (messageGroup: Message[], index: number) => {
    const messageText = (
      groupedMessages[index][messageGroup.length - 1].content as TextMessageContent
    ).text;
    return (
      messageGroup[messageGroup.length - 1]?.role === Persona.BUILDER &&
      index <= groupedMessages.length - 2 &&
      !messageText.startsWith(APPLIED_MANUAL_CODE_CHANGES as string) &&
      !messageText.startsWith(BUILDER_SELECTED_SKELETON_PREFIX as string) &&
      !isSkeletonPickedPrompt(messageText) &&
      !props.isAppRun &&
      (index > 0 || (index === 0 && !props.isAllPreviousMessagesLoaded))
    );
  };

  useEffect(() => {
    const containerNode = (props.mainContainerElementRef || containerRef).current;

    if (containerNode) {
      if (!isScrollDownVisible) {
        setTimeout(() => {
          handleScrollDown(true);
        }, 1);
      }
    }
  }, [props.messages]);

  const handleScroll = () => {
    const containerNode = (props.mainContainerElementRef || containerRef).current;
    const endOfChatNode = endOfChatElement.current;

    if (containerNode && endOfChatNode) {
      const containerBottomPosition = containerNode.scrollTop + containerNode.clientHeight;
      const endOfChatPosition = endOfChatNode.offsetTop;
      const bufferForEndOfChat = containerNode.clientHeight * 0.3;
      const isEndOfChatVisible = containerBottomPosition >= endOfChatPosition - bufferForEndOfChat;
      setScrollDownVisible(!isEndOfChatVisible);
    }
  };

  useEffect(() => {
    handleScroll();
    // setTimeout is added so that it scrolls after messages are loaded
    // because if the messages are already loaded, the call happens instantly
    // so by the time the app run checks whether the header should be displayed or not
    // the messages are not loaded yet, so it doesn't know that the header is hidden
    setTimeout(() => {
      if (props.handleChatContainerScrolling) {
        props.handleChatContainerScrolling();
      }
    }, 10);
  }, [props.messages]);

  useEffect(() => {
    const containerNode = (props.mainContainerElementRef || containerRef).current;

    containerNode?.addEventListener('scroll', handleScroll);

    return () => {
      containerNode?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [searchParams, _] = useSearchParams();
  const tab = searchParams.get('tab');
  const isTestTabOpen = Number(tab) === TabBarItemsIndex.APP_TEST;

  // eslint-disable-next-line max-lines-per-function
  const renderMessageGroups = () => {
    // eslint-disable-next-line max-lines-per-function
    return [...groupedMessages].map((messageGroup, index) => (
      <div
        className={classNames('flex flex-col', {
          'bg-system-emerald':
            props.activeBuilderSessionStateId &&
            isTestTabOpen &&
            index < groupedMessages.length - 2 &&
            props.activeBuilderSessionStateId ===
              messageGroup[messageGroup.length - 1].builderSessionStateId,

          'bg-system-hover-blue':
            messageGroup[messageGroup.length - 1].builderSessionStateId === viewedMessageId,
        })}
        key={index}
      >
        <MessageGroup
          messages={messageGroup}
          source={messageGroup[0].source}
          sourceSuffix={
            messageGroup[0].role === Persona.DEVELOPER
              ? ' 🔧'
              : messageGroup[0].role === Persona.PRODUCT_MANAGER
              ? ' 💬'
              : ''
          }
          isFirstGroup={index === 0}
          isLastGroup={index === groupedMessages.length - 1}
          scrollDownToLatestMessage={handleScrollDown}
          loadMoreMessages={props.loadMoreMessages}
          isAllPreviousMessagesLoaded={props.isAllPreviousMessagesLoaded}
          isEditableMessage={isEditableMessage(messageGroup, index)}
        />
        {messageGroup[0].source.type === MessageSourceType.System &&
          !!props.revertFunction &&
          (index > 1 || messageGroup[messageGroup.length - 1].role === Persona.DEVELOPER) &&
          index < groupedMessages.length - 2 && (
            <div
              className={classNames('flex gap-1 mb-3 ml-[2.8rem] mr-5 md:mr-0', {
                'justify-end': isMobileDevice(),
              })}
            >
              <RevertMessageButton
                revertFunction={props.revertFunction}
                builderSessionStateId={messageGroup[messageGroup.length - 1].builderSessionStateId}
              />

              {messageGroup[messageGroup.length - 1].role === Persona.DEVELOPER &&
                messageGroup[messageGroup.length - 1].hasAppVersion && (
                  <>
                    <ViewVersionButton
                      viewAppVersionFunction={props.viewAppVersionFunction}
                      builderSessionStateId={
                        messageGroup[messageGroup.length - 1].builderSessionStateId
                      }
                    ></ViewVersionButton>

                    <PublishVersionButton
                      publishVersionFunction={props.onPublishVersion}
                      builderSessionStateId={
                        messageGroup[messageGroup.length - 1].builderSessionStateId
                      }
                    ></PublishVersionButton>

                    {!props.isTemplate && (
                      <TestVersionButton
                        builderSessionStateId={
                          messageGroup[messageGroup.length - 1].builderSessionStateId
                        }
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        testVersionFunction={props.testVersionFunction}
                        hasReactivationDelay={true}
                      ></TestVersionButton>
                    )}
                  </>
                )}
            </div>
          )}
      </div>
    ));
  };

  return (
    <div
      className={classNames('flex overflow-y-hidden', {
        relative: !props.mainContainerElementRef,
      })}
    >
      <div
        ref={containerRef}
        className="flex flex-col flex-none w-full overflow-y-scroll no-scrollbar"
      >
        {renderMessageGroups()}
        {props.submitStarterPrompt && userPermissions?.isUserAllowedToPrompt && (
          <StarterPrompts
            starterPrompts={props.starterPrompts}
            submitStarterPrompt={props.submitStarterPrompt}
            showExploreTemplates={props.showExploreTemplates}
          />
        )}

        {!props.isAppRun && (
          <ChatActionsForUser
            selectedTabIndex={props.selectedTabIndex}
            updateFiles={props.updateFiles}
            isTemplate={props.isTemplate}
          ></ChatActionsForUser>
        )}
        <span ref={endOfChatElement} className="flex opacity-0 w-0 h-0"></span>
      </div>
      <button
        className={classNames('flex absolute bottom-0 right-0 p-4 transition-opacity w-14', {
          'opacity-0 pointer-events-none': !isScrollDownVisible,
        })}
        style={{ bottom: `${props.scrollDownButtonPb || 0}px` }}
        onClick={() => handleScrollDown()}
      >
        <LazyScrollDown />
      </button>
    </div>
  );
};

export default MessageList;
